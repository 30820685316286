.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=file].drop-file {
  border-radius: 16px;
  padding: 24px;
  width: 256px;
  min-height: 128px;
  border: 2px dashed #ccc;
}

input[type=file].drop-file:hover, input[type=file].drop-file:focus, input[type=file].drop-file:active,
input[type=file].drop-file.drag-over {
  border-color: rgb(74, 112, 237);
}



.file-result {
  margin: 20px;
  border-radius: 20px;
  border: 2px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  line-break: anywhere;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}